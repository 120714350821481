<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div class="h-screen flex w-full" id="overtimestat">
      <vs-table class="w-full" ref="table" search :data="calendarStat">
        <template slot="thead">
          <vs-th sort-key="name">이름</vs-th>
          <vs-th sort-key="entry_date">입사일</vs-th>
          <vs-th sort-key="resetDate">휴가기준일</vs-th>
          <vs-th sort-key="max_timeoff">총휴가일수</vs-th>
          <vs-th sort-key="usedTimeoff">휴가사용일수</vs-th>
          <vs-th sort-key="remainTimeoff">잔여휴가일수</vs-th>
        </template>

          <template slot-scope="{data}">
            <tbody>
              <!-- <vs-tr :style="[tr.level >= 9 ? {'background-color': '#CCFFCC' } : tr.level >= 8 ? {'background-color': '#FFFFCC' } : {'background-color': '#DDCCFF'}]" :data="tr" :key="indextr" v-for="(tr, indextr) in data"> -->
              <vs-tr :style="[tr.level >= 9 ? {'background-color': '#ddFFdd' } : {'background-color': '#eeddFF'}]" :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>{{ tr.name }}</vs-td>
                <vs-td>{{ tr.entry_date }}</vs-td>
                <vs-td>{{ tr.resetDate }}</vs-td>
                <vs-td>{{ tr.max_timeoff }}</vs-td>
                <vs-td>{{ tr.usedTimeoff }}</vs-td>
                <vs-td>{{ tr.remainTimeoff }}</vs-td>
              </vs-tr>
            </tbody>
          </template>
      </vs-table>
    </div>
</template>

<script>
// import axios from 'axios'
import moment from 'moment'
export default {
  data() {
    return {
      calendarStat: [{}],
      writerOption: [{}],
      holydayData: [],
    }
  },
  methods: {
    countTimeoffDay(timeoff){
      const dayDiff = moment(timeoff.end).add(1,'day').diff(moment(timeoff.start),'day');
      let holydays = 0;
      holydays = parseInt(dayDiff / 7) * 2;
      console.log("timeoff: ", dayDiff, dayDiff % 7, dayDiff % 7 >= moment(timeoff.end).day() + 2);
      if(dayDiff % 7){
        if(moment(timeoff.start).day() == 0 || moment(timeoff.end).day() == 6){
          holydays += 1;
        }else if(dayDiff % 7 >= moment(timeoff.end).day() + 2){
          holydays += 2;
        }
        holydays += this.holydayData.filter(e => e && e.locdate >= moment(timeoff.start).format('YYYYMMDD') && e.locdate <= moment(timeoff.end).format('YYYYMMDD') ).length
      }
      return dayDiff - holydays;
    },
  },
  created() {
    const thisIns = this;
    const target = moment(this.$route.query.target);
    this.$http.post('/api/getVarList/writer', {})
      .then(function (response) {
        console.log(response)
        thisIns.writerOption = response.data;
        thisIns.writerOption = thisIns.writerOption.filter(e => moment(e.entry_date) > moment('2000-01-01') && e.level > 5);
        for(let i in thisIns.writerOption){
          const resetDate = moment(thisIns.writerOption[i].entry_date).year(moment().format('YYYY'))
          if(moment() > resetDate){
            thisIns.writerOption[i].resetDate = resetDate.format('YYYY-MM-DD')
          }else{
            thisIns.writerOption[i].resetDate = resetDate.subtract(1,'year').format('YYYY-MM-DD')
          }
          thisIns.writerOption[i].entry_date = moment(thisIns.writerOption[i].entry_date).format('YYYY-MM-DD')
          // console.log(thisIns.writerOption[i].entry_date)
        }
        thisIns.calendarStat = JSON.parse(JSON.stringify(thisIns.writerOption));
        return thisIns.$http.post('/api/board/getHolydayList', {data: target.year()})
      }).then(function (response) {
        thisIns.holydayData = response.data;
        console.log(thisIns.holydayData)
        return thisIns.$http.post('/api/board/getTimeOffListYear', {data: target})
      }).then(function (response) {
        console.log(response)
        for(let writer of thisIns.calendarStat){
          const entryDate = writer.entry_date;
          const resetDate = moment(entryDate).year(target.format('YYYY'))
          if(target >= resetDate){
            writer.startDate = resetDate.format('YYYY-MM-DD');
            writer.endDate = resetDate.add(1, 'year').format('YYYY-MM-DD');
          }else{
            writer.endDate = resetDate.format('YYYY-MM-DD');
            writer.startDate = resetDate.subtract(1,'year').format('YYYY-MM-DD');
          }
          // if(!writer.max_timeoff){
          //   // console.log('entry:', thisIns.writer_s.entry_date, moment().diff(moment(thisIns.writer_s.entry_date), 'year'));
          //   // writer.max_timeoff = 15 + parseInt(target.diff(moment(writer.entry_date), 'year') / 2);
            
          //   let contYear = target.diff(moment(writer.entry_date), 'year');
          //   if(contYear == 0){
          //     writer.max_timeoff = moment().diff(moment(writer.entry_date), 'month');
          //   }else{
          //     writer.max_timeoff = 15 + parseInt(contYear / 2);
          //   }
          // }
          
          if(!writer.max_timeoff){
            writer.max_timeoff = 15;
          }
          let contYear = target.diff(moment(writer.entry_date), 'year');
          if(contYear == 0){
            writer.max_timeoff = moment().diff(moment(writer.entry_date), 'month');
          }else{
            writer.max_timeoff = writer.max_timeoff + parseInt((contYear - 1) / 2);
          }
          writer.usedTimeoff = 0;
          writer.remainTimeoff = writer.max_timeoff;
          for(let timeoff of response.data.filter(e => e.writer == writer.name && moment(e.start) >= moment(writer.startDate) && moment(e.start) < moment(writer.endDate))){
            // console.log(timeoff, writer.startDate, writer.endDate)
            if(timeoff.state >= 0){
              if(timeoff.factor){
                const days =  thisIns.countTimeoffDay(timeoff);//moment(timeoff.end).add(1,'day').diff(moment(timeoff.start),'day')
                writer.usedTimeoff += days * timeoff.factor;
                writer.remainTimeoff -= days * timeoff.factor;
              }
            }
          }
        }
        thisIns.calendarStat.sort((a,b) => b.level - a.level);
      }).catch(function (error) {
        console.log(error)
        var errorText = error.toString();
        if(errorText.includes('401')){
          alert('로그인 해주세요!')
          thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
        }
        thisIns.$vs.notify({
          title:'Error',
          text: error,
          color:'danger',
          iconPack: 'feather',
          icon:'icon-alert-circle'})
        thisIns.$vs.loading.close(); 
      });   
  }
}
</script>
